import Image from 'next/image'
import Link from '../../components/custom/Link'
import { useContext, useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Autoplay } from 'swiper/core'
import 'swiper/swiper.min.css'
import { FaExclamationCircle } from 'react-icons/fa'
import { useRouter } from 'next/router'
import { useRef } from 'react'
import {
  FaShoppingCart,
  FaPlus,
  FaArrowRight,
  FaHeartBroken,
  FaMinus,
  FaArrowLeft,
  FaHeart,
  FaInfoCircle
} from 'react-icons/fa'


import LoadingAnimation from '../../components/custom/LoadingAnimation'
import { ThemeContext } from '../../contexts/ThemeContext'
import { BiHeart } from 'react-icons/bi'
import { BsFillBagHeartFill } from 'react-icons/bs'
import { CurrencyContext } from '../../contexts/CurrencyContext'
import { CartContext } from '../../contexts/CartContext'
import { UserContext } from '../../contexts/UserContext'
import { WishlistContext } from '../../contexts/WishlistContext'
import { Snackbar } from './Snackbar'
import NoProducts from '../../public/images/pnf.jpg'
import { setImageSrc } from '../../utils/config'
import { useDeviceSize } from '../../utils/useDeviceSize'
import CustomPopup from '../../components/CustomPopup';
import DynamicQuantityButton from '../../pages/product/DynamicQuantityButton'
SwiperCore.use([Navigation, Autoplay])

// Related products for product details
function RelatedProducts ({
  products,
  isLoading,
  isSpecificGrid,
  view,
  productSettings,
  sliderConfigData
}) {
 
  const router = useRouter() // Initialize the useRouter hook
  const { token, userDetails } = useContext(UserContext)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarProduct, setSnackbarProduct] = useState(null)
  const gridClasses = 'grid lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 gap-6'

  const theme = useContext(ThemeContext)
  const { formatCurrency } = useContext(CurrencyContext)
  const { cart, addToCart, updateQuantity, isSnackbarVisible } =
    useContext(CartContext)
  const { wishlist, addToWishlist, removeFromWishlist } =
    useContext(WishlistContext)
  const [wishlistStatus, setWishlistStatus] = useState(
    products?.reduce(
      (acc, product) => ({ ...acc, [product.id]: 'not_added' }),
      {}
    )
  )
  const { isMobile, isTablet, isLaptopL } = useDeviceSize()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [isRemoved, setIsRemoved] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to handle popup visibility
  const [popupConfig, setPopupConfig] = useState({}); // State to handle popup configuration
  const userType = userDetails?.user_type || 0;

  const isWishlisted = product => {
    if (!wishlistStatus || !product || !product.id) {
      // If wishlistStatus is undefined, or product is undefined, or product.id is undefined, return false
      return false
    }
    return wishlistStatus[product.id] !== 'not_added'
  }
  const handleImageLoaded = event => {
    // Directly hide the LoadingAnimation sibling of the img tag
    event.currentTarget.previousSibling.style.display = 'none'
  }
  const isProductInWishlist = (productId) => {
   
  
    // Normalize the given productId to string for comparison
    const normalizedProductId = String(productId);
  
    const found = wishlist.some((item) => {
      const normalizedProductId1 = String(item.product_id1);
      const normalizedItemProductId = String(item.product_id);
  
   
  
      if (
        normalizedItemProductId === normalizedProductId ||
        normalizedProductId1 === normalizedProductId
      ) {
        
        return true;
      }
  
      return false;
    });
  
  
  
    return found;
  };
  
    const toggleWishlist = async (product, event) => {
    
      event.preventDefault();
      if (!userDetails) {
        const url = window.location.href;
        document.cookie = `initialReferrer=${encodeURIComponent(url)}; path=/`;
  
        router.push('/auth/SignIn');
      } else {
  
        if (isProductInWishlist(product.id || product.product_id1)) {
          await removeFromWishlist(product);
          setWishlistStatus(prevStatus => ({ ...prevStatus, [product.id]: "removed" }));
        } else {
          await addToWishlist(product);
          setWishlistStatus(prevStatus => ({ ...prevStatus, [product.id]: "added" }));
        }
      }
    };
  
  

  // const [quantity, setQuantity] = useState(sd
  //   products.reduce((acc, product) => ({ ...acc, [product.id]: 1 }), {})
  // );
  useEffect(() => {
    const newWishlistStatus = products?.reduce((acc, product) => {
      const wishlistItem = wishlist.find(item => item.product_id === product.id)
      if (wishlistItem) {
        return { ...acc, [product.id]: 'added' }
      }
      return { ...acc, [product.id]: 'not_added' }
    }, {})

    setWishlistStatus(newWishlistStatus)
  }, [products, wishlist])
// Add to cart modification - 16-08-2024
  const handleAddToCart = (product, event) => {
    if (!userDetails) {
      const url = window.location.href;
      document.cookie = `initialReferrer=${encodeURIComponent(url)}; path=/`;

      router.push('/auth/SignIn');
    } else {
      event.preventDefault();
      addToCart(product);
      setSnackbarProduct(product);
      setSnackbarOpen(true);
    }
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleDisabledClick = (event) => {
    event.preventDefault();
    setPopupConfig({
      type: 'info',
      title: 'Preview Mode',
      subText: 'Actions like "Add to Cart" and "Wishlist" are disabled during preview.',
      onConfirm: closePopup,
      onClose: closePopup,
      showConfirmButton: false,
      showCancelButton: false,
      autoClose: 3,
    });
    setIsPopupOpen(true);
  };

  function truncate (str, num) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num > 3 ? num - 3 : num) + '...'
  }
  // const increaseQuantity = (product) => {
  //   setQuantity({ ...quantity, [product.id]: quantity[product.id] + 1 });
  //   updateQuantity(product, quantity[product.id] + 1);
  // };

  // const decreaseQuantity = (product) => {
  //   if (quantity[product.id] > 1) {
  //     setQuantity({ ...quantity, [product.id]: quantity[product.id] - 1 });
  //     updateQuantity(product, quantity[product.id] - 1);
  //   }
  // };

  // calculate Grid cols
  const gridColumnClass = () => {
    const count = productSettings?.display_products_count || 1
    switch (count) {
      case 2:
        return 'md:grid-cols-4'
      case 3:
        return 'lg:grid-cols-4'
      // Add more cases as needed
      default:
        return 'xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1'
    }
  }
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  // Toggle wishlist status
  useEffect(() => {
    const newWishlistStatus = products?.reduce((acc, product) => {
      const wishlistItem = wishlist.find(item => item.product_id === product.id)
      if (wishlistItem) {
        return { ...acc, [product.id]: 'added' }
      }
      return { ...acc, [product.id]: 'not_added' }
    }, {})

    setWishlistStatus(newWishlistStatus)
  }, [wishlist])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Add skeleton loader
  if (isLoading) {
    return <LoadingAnimation />
  } else if (products?.length === 0) {
    // Show "no products" image only after loading has finished
    // products rendering
    return (
      <>
       <div className="flex flex-col justify-center items-center my-4">
         <Image
        src={NoProducts}
        alt="No products found"
        className="mx-auto"
        width={300} // Set appropriate width
        height={200} // Set appropriate height
        quality={75}
      />
      </div>
      </>
    )
  } else {
    // products rendering
    return (
      <>
        {products ? (
          <div className='border lg:p-3 relative shadow-xl rounded-md'>
            <Swiper
              navigation={{
                prevEl: navigationPrevRef.current, // Use the ref here
                nextEl: navigationNextRef.current // And here
              }}
              onBeforeInit={swiper => {
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current
              }}
              slidesPerView={1}
              spaceBetween={30}
              // loop={true}
              breakpoints={{
                640: { slidesPerView: 2 },
                768: { slidesPerView: 2 },
                1024: { slidesPerView: 3 },
                1440: { slidesPerView: sliderConfigData?.numberOfProducts ?? 4 }
              }}
              autoplay={{
                delay: sliderConfigData?.rotationSeconds ? (sliderConfigData?.rotationSeconds * 1000) : 6500 ,
                disableOnInteraction: false
              }}
            >
              {products?.map(product => {
                  // Ensure both IDs are converted to strings before comparison
          const isInCart = cart?.some((item) => {
         
            const isMatch = String(item.product_id) === String(product.id);
          
            return isMatch;
          });
        
          const isInWishlist = wishlist?.some((item) => {
            // Normalize and compare both product_id and product_id1 with product.id
            const isMatch =
              String(item.product_id) === String(product.id) ||
              String(item.product_id1) === String(product.id);
          
            return isMatch;
          });

                return (
                  <SwiperSlide key={product.id} className='w-full h-full'>
                  <div className='w-full h-full flex justify-center items-center'>
                    <div className='bg-white p-4 shadow-sm rounded-xl w-full h-full max-w-screen-md m-4 transition-transform transform hover:scale-105'>
                      {/* Product Image */}
                      <div className='relative h-full w-full mb-4 group cursor-pointer'>
                        {/* Original Image */}
                        <a
                          href={`${product.url}${
                            theme.siteStatus === 0 ? `?pid=${theme.token}` : ''
                          }`}
                        >
                          {/* <LoadingAnimation /> */}
                          <Image
                            src={setImageSrc(product.image_url || product.image, product.name, 's400')}
                            alt={product.name}
                            loading="lazy"
                            width={400} // Set the desired width
                            height={400} // Set the desired height
                            className='rounded-md'
                            quality={75}
                            style={{ animation: 'fadeIn 0.5s ease forwards' }} // This inline style is for the fade-in animation once the image is loaded
                          />
                
                          {/* Hover Image */}
                          {product.hoverImage && (
                            <Image
                              src={setImageSrc(product.image_url || product.hoverImage, product.name, 's400')}
                              alt={product.name}
                              loading="lazy"
                              width={400} // Set the desired width
                              height={400} // Set the desired height
                              quality={75}
                              className='hidden lg:block absolute top-0 left-0 transition-all duration-700 transform translateY(100%) scale-95 brightness-95 opacity-0 group-hover:opacity-100 group-hover:translateY(0) group-hover:scale-100 group-hover:brightness-110 group-hover:shadow-xl'
                            />
                          )}
                        </a>
                      </div>
                      <div className='flex justify-between items-center'>
                        <div
                       
                          className='text-left flex-grow'
                        >
                          <div style={{ height: '3.6rem' }}>
                            {sliderConfigData?.isProductNameVisible === 0 ? '' : (
                              <h1
                                style={{
                                  display: '-webkit-box',
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden'
                                }}
                                title={product.name}
                                className='font-semibold text-lg text-primary break-words'
                              >
                                {product.name || product.product_name}
                              </h1>
                            )}
                          </div>
                          <div>
                            {sliderConfigData?.isProductNameVisible === 0 ? '' : (
                              <h1 className='text-center mb-2 font-semibold text-md text-gray-700'>
                                {product.sku ? 'SKU: ' + product.sku : ''}
                              </h1>
                            )}
                          </div>
                          <div className='flex row justify-between'>
                            <div>
                              
                                    {/* price rule modification */}
                                    {
                              sliderConfigData?.isPriceVisible === 1 || product?.price ?

                              <h2 className="font-bold text-xl xl:text-xl text-gray-700 mb-2 ">
                                {
                                  ((product.isSpecialPrice == 1 || product.special_price_status == 1 ) && (userType == 1 || userType == 0)) ? (
                                    <>
                                    {product.specialPrice ?  <span className="text-green-700">{  formatCurrency(product.specialPrice) } </span>
                                    :<span className="text-green-700">{formatCurrency(product.special_amount) } </span>}
                                     

                                      <span className="ml-1 line-through text-red-500 text-lg">{formatCurrency(product.price)} </span>

                                    </>

                                  ) : (<span>{formatCurrency(product?.price)} </span>)
                                }
                              </h2> : ''
                            } 

                            </div>
                     
                          </div>
                          <div>
                              <div className='ml-4 flex  items-center justify-end relative z-100'>
                                <div className='flex row'>
                                  {/* Wishlist Button positioned at top right */}
                                  <div className='top-2 right-2 '>
                                    <button
                                      onClick={(event) => {
                                        if (theme.siteStatus !== 0) {
                                          event.stopPropagation();
                                          toggleWishlist(product,event);
                                        } else {
                                          handleDisabledClick(event);
                                        }
                                      }}
                                      className='bg-primary hover:bg-gray-800 text-white text-lg w-10 h-10 rounded-full flex items-center justify-center transition transform duration-500 hover:scale-110 cursor-pointer'
                                    >
                                       <div className={`${isInWishlist ? "animate-pulse" : ""}`}>
    {isInWishlist ? (
      <FaHeart size={24} />
    ) : (
      <BiHeart
        size={24}
        className="text-white stroke-current stroke-3 animate-pulse"
      />
    )}
  </div>
                                    </button>
                                  </div>
                                  <div className='relative group mx-1'>
                                    {/* Tooltip */}
                               
                                  {/* Model product id */}
                                    {product?.mProductId?.toString().startsWith('m') ? <DynamicQuantityButton product={product} uiMode="1" isCart={false} isRelated="1" /> :    <div className='relative group mx-1'>
                                    {/* Tooltip */}
                               
                                    {isInCart ? (
                                      <a
                                        href={`${product.url}${
                                          theme.siteStatus === 0 ? `?pid=${theme.token}` : ''
                                        }`}
                                        className='bg-primary hover:bg-alternative text-white w-10 h-10 rounded-full flex items-center justify-center transition transform duration-500 hover:scale-110'
                                      >
                                        <FaInfoCircle size={20} />
                                      </a>
                                    ) : (
                                      <button
                                        onClick={(event) => {
                                          if (theme.siteStatus !== 0) {
                                            event.stopPropagation();
                                            handleAddToCart(product,event);
                                          } else {
                                            handleDisabledClick(event);
                                          }
                                        }}
                                        className='bg-primary hover:bg-alternative text-white w-10 h-10 rounded-full flex items-center justify-center transition transform duration-500 hover:scale-110'
                                      >
                                        <BsFillBagHeartFill size={20} />
                                      </button>
                                    )}
                                  </div>}

                                  </div>
                                </div>
                              </div>
                            </div>
                          {parseFloat(product.weight) > 0 &&
                          userDetails &&
                          (userDetails?.user_type === 2 || userDetails?.user_type === 3) ? (
                            <h1 className='font-semibold text-md text-gray-600 mb-1'>
                              Weight: {product.weight} Gm
                            </h1>
                          ) : null}
                
                          {product.touch !== null &&
                          product.touch != 0 &&
                          product.touch != undefined &&
                          userDetails &&
                          (userDetails?.user_type === 2 || userDetails?.user_type === 3) ? (
                            <h1 className='font-semibold text-md mb-2 text-gray-600'>
                              Touch : {product.touch}
                            </h1>
                          ) : null}
                
                          {/* {
                            product.productCount > 1 ? (
                              <p className="text-lg font-semibold text-gray-700 flex items-center gap-2">
                                <FaExclamationCircle className="h-5 w-5 text-primary" />
                                Only <span className="text-primary font-bold">{product.productCount}</span> units left
                              </p>
                            ) : product.productCount === 1 ? (
                              <p className="text-lg font-semibold text-gray-700 flex items-center gap-2">
                                <FaExclamationCircle className="h-5 w-5 text-primary" />
                                Only <span className="text-primary font-bold">1</span> unit left
                              </p>
                            ) : null
                            }
                              */}
                        </div>
                        {/* Add to Cart Button */}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                )
              })}
            </Swiper>
            {
              // sliderConfigData.products.length > sliderConfigData.numberOfProducts  &&
              <div>
                <div
                  ref={navigationNextRef}
                  className='swiper-button-next z-10 bg-gray-200 p-2 rounded-full absolute top-1/2 right-0 transform -translate-y-1/2 cursor-pointer'
                >
                  <FaArrowRight color='black' size={20} />
                </div>
                <div
                  ref={navigationPrevRef}
                  className='swiper-button-prev z-10 bg-gray-200 p-2 rounded-full absolute top-1/2 left-0 transform -translate-y-1/2 cursor-pointer'
                >
                  <FaArrowLeft color='black' size={20} />
                </div>
              </div>
            }
            <Snackbar
              product={snackbarProduct}
              isOpen={snackbarOpen}
              setIsOpen={setSnackbarOpen}
            />
          </div>
        ) : (
          false
        )}
         <CustomPopup
          isOpen={isPopupOpen}
          type={popupConfig.type}
          title={popupConfig.title}
          subText={popupConfig.subText}
          onConfirm={popupConfig.onConfirm}
          onClose={popupConfig.onClose}
          autoClose={popupConfig.autoClose}
          showConfirmButton={popupConfig.showConfirmButton}
          showCancelButton={popupConfig.showCancelButton}
        />
      </>
    )
  }
}

export default RelatedProducts
